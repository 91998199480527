import {FC, Fragment, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
//formik
import {Formik} from 'formik';
//translation
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
import {resetError, setError} from 'redux/error-service/action';
//API
import {API} from 'core/API';
//validation
import {inviteUsersValidationAddingSchema, inviteUsersValidationSchema} from 'core/validation';
//types
import {
  CreateMemberRequest,
  IInviteUserRequest,
  InviteUsersOrganizationRequest,
  ORGANISATION_ACTIVE_STATUS,
} from '@joc/api-gateway';
import {InvitedUserColleagues} from 'core/types';
import {
  CurrentTab,
  getIsSetPreferredTab,
} from 'components/Organization/Volunteers/VolunteersPopups/InvitePopup/helpers';
//constants
import {INITIAL_INVITE_COLLEAGUES, INITIAL_INVITE_COLLEAGUES_BY_ADDING} from 'core/constants';
//components
import Loader from 'shared/components/Loader';
import WhiteContainer from 'shared/components/WhiteContainer';
import ResponseFailure from 'shared/components/ResponseFailure';
import CongratsPopup from 'components/Organization/CongratsPopup';
import PopupConfirmation from 'shared/components/PopupConfirmation';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputImportList from 'shared/components/FormInputs/InputImportList';
import InvitedColleaguesTable from 'components/Organization/InvitedColleaguesTable';
import InviteColleaguesForm from './InviteColleaguesForm';
import ButtonTabs from './InviteColleaguesTabs';
//styles
import styles from './InviteColleagues.module.scss';

const InviteColleagues: FC = () => {
  const dispatch = useDispatch();

  const [isInviteSuccess, setIsInviteSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<IInviteUserRequest>(INITIAL_INVITE_COLLEAGUES);
  const [initialFormikValuesAdding] = useState<any>(INITIAL_INVITE_COLLEAGUES_BY_ADDING);

  const [invitedColleaguesList, setInvitedColleaguesList] = useState<Array<IInviteUserRequest>>();
  const [isShowConfirmationChange, setIsShowConfirmationChange] = useState(false);
  const [isShowConfirmClear, setIsShowConfirmClear] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isShowConfirmationClose, setIsShowConfirmationClose] = useState(false);
  const [currentTab, setCurrentTab] = useState<CurrentTab>(CurrentTab.form);
  const [preferredTab, setPreferredTab] = useState<CurrentTab>();

  const error = useSelector((store: Store) => store.errorRedux.error);
  const orgId = useSelector((store: Store) => store.organizationRedux.organizationInfo?.id);
  const schoolId = useSelector(selectorGetSchoolId);
  const orgStatus = useSelector((store: Store) => store.organizationRedux.organizationInfo?.organizationActiveStatus);

  const {t} = useTranslation(['inviteColleagues', 'errors', 'messages', 'buttons']);

  const isEmptyObj = (obj: any) => setIsEmpty(!Object.keys(obj).length);

  const subTitleForPopup = useMemo(() => {
    return !!schoolId ? t('inviteColleagues:school') : t('inviteColleagues:org');
  }, [schoolId]);

  const isOrgSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  const handlerResetError = () => dispatch(resetError());

  const submitClickHandler = async (values: Array<IInviteUserRequest>, isAddingByAdmin?: boolean) => {
    setIsLoading(true);
    let colleaguesListWithPostionAndRoles: Array<IInviteUserRequest> = [];
    if (!orgId) return dispatch(setError(`${t('errors:sorry')} ${t('errors:cantFindOrgId')}`, 422));
    try {
      const roles = await API.getAllRoles();
      colleaguesListWithPostionAndRoles = values.map((user) => {
        const userRoleId =
          user.roleId || roles.find((role) => role.roleName === (user as InvitedUserColleagues).roleName)?.id;

        if (!!!user.phoneNumber) user.phoneNumber = undefined;

        if (userRoleId)
          return {
            ...user,
            firstName: user.firstName.trim(),
            lastName: user.lastName.trim(),
            roleId: userRoleId,
            positionId: 2,
          };

        return user;
      });

      if (isAddingByAdmin) {
        await API.registerMember(
          orgId,
          CreateMemberRequest.fromJS({...colleaguesListWithPostionAndRoles[0], organizationId: +orgId})
        );
      } else {
        await API.inviteUsers(
          +orgId,
          orgId.toString(),
          InviteUsersOrganizationRequest.fromJS({invitedUsers: colleaguesListWithPostionAndRoles})
        );
      }

      setIsInviteSuccess(true);
    } catch (error) {
      console.error(error);
      dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
    } finally {
      currentTab === CurrentTab.form
        ? setInitialValues(INITIAL_INVITE_COLLEAGUES)
        : setInvitedColleaguesList(colleaguesListWithPostionAndRoles);
      setIsLoading(false);
    }
  };

  const tabClickHandler = (tab: CurrentTab) => () => {
    if (currentTab !== tab) {
      const isSetPreferredTab = getIsSetPreferredTab(currentTab, isEmpty, invitedColleaguesList?.length);
      if (isSetPreferredTab) {
        setPreferredTab(tab);
        setIsShowConfirmationChange(true);
      } else {
        setCurrentTab(tab);
      }
    }
  };

  const confirmationSubmitClickHandler = () => {
    if (preferredTab) {
      setCurrentTab(preferredTab);
      setPreferredTab(undefined);
    }
  };

  return (
    <Fragment>
      {isInviteSuccess ? (
        <CongratsPopup
          classNames={styles.popup__title}
          doneButtonClickHandler={() => setIsInviteSuccess(false)}
          subtitle={`${
            currentTab === CurrentTab.adding
              ? t('messages:colleaguesAddedSuccessfully')
              : t('messages:colleaguesInvitedSuccessfully')
          } ${subTitleForPopup}\n${currentTab !== CurrentTab.adding ? t('inviteColleagues:messageSent') : ''}`}
        />
      ) : error.state ? (
        <div className={styles.error}>
          <ResponseFailure
            message={error.message}
            buttonClickHandler={handlerResetError}
            buttonTitle={t('errors:tryAgain')}
            styleTable
          />
        </div>
      ) : (
        <div className={styles.container}>
          <WhiteContainer title={t('inviteColleagues:addColleagues')}>
            <ButtonTabs currentTab={currentTab} tabClickHandler={tabClickHandler} />
            {currentTab === CurrentTab.form && (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={inviteUsersValidationSchema}
                onSubmit={(values) => submitClickHandler([values])}
              >
                <InviteColleaguesForm
                  isOrgSuspended={isOrgSuspended}
                  isLoading={isLoading}
                  isShowConfirmationChange={isShowConfirmationChange}
                  isEmptyObj={isEmptyObj}
                  setIsShowConfirmationChange={setIsShowConfirmationChange}
                  confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                />
              </Formik>
            )}
            {currentTab === CurrentTab.list && (
              <div className={styles.list}>
                <div className={styles.list__wrapper}>
                  <div className={styles.list__nav}>
                    <InputImportList
                      setStateValue={setInvitedColleaguesList}
                      listLength={invitedColleaguesList?.length}
                      invitedUserType="colleague"
                      exampleFilePath={`${process.env.PUBLIC_URL}/assets/documents/inviteColleaguesTemplate.xlsx`}
                    />
                    {!!invitedColleaguesList?.length && (
                      <ButtonDefault
                        classList={['secondary']}
                        title={t('buttons:button.clearAll')}
                        clickHandler={() => {
                          setIsShowConfirmClear(true);
                        }}
                      />
                    )}
                  </div>
                  {!!invitedColleaguesList?.length && <InvitedColleaguesTable colleagues={invitedColleaguesList} />}
                </div>
                {!!invitedColleaguesList?.length &&
                  (isLoading ? (
                    <Loader />
                  ) : (
                    <ButtonDefault
                      clickHandler={() => submitClickHandler(invitedColleaguesList)}
                      classList={['primary', 'lg']}
                      title={t('buttons:button.confirm')}
                      disabled={isOrgSuspended}
                    />
                  ))}
                {isShowConfirmationChange && (
                  <PopupConfirmation
                    confirmClickHandler={() => {
                      confirmationSubmitClickHandler();
                      setIsShowConfirmationChange(false);
                      setInvitedColleaguesList([]);
                    }}
                    cancelClickHandler={() => setIsShowConfirmationChange(false)}
                    setIsShowPopup={setIsShowConfirmationChange}
                  />
                )}

                {isShowConfirmClear && (
                  <PopupConfirmation
                    confirmClickHandler={() => {
                      confirmationSubmitClickHandler();
                      setInvitedColleaguesList([]);
                      setIsShowConfirmClear(false);
                    }}
                    cancelClickHandler={() => setIsShowConfirmClear(false)}
                    setIsShowPopup={setIsShowConfirmClear}
                  />
                )}
              </div>
            )}
            {currentTab === CurrentTab.adding && (
              <Formik
                enableReinitialize
                initialValues={initialFormikValuesAdding}
                validationSchema={inviteUsersValidationAddingSchema}
                onSubmit={(values) => submitClickHandler([values], true)}
              >
                <InviteColleaguesForm
                  isShowPassword
                  isOrgSuspended={isOrgSuspended}
                  isLoading={isLoading}
                  isShowConfirmationChange={isShowConfirmationChange}
                  isEmptyObj={isEmptyObj}
                  setIsShowConfirmationChange={setIsShowConfirmationChange}
                  confirmationSubmitClickHandler={confirmationSubmitClickHandler}
                  checkEmailExisting
                  isSchool={!!schoolId}
                />
              </Formik>
            )}
            {isShowConfirmationClose && (
              <PopupConfirmation
                confirmClickHandler={() => {
                  confirmationSubmitClickHandler();
                }}
                cancelClickHandler={() => setIsShowConfirmationClose(false)}
                setIsShowPopup={setIsShowConfirmationClose}
              />
            )}
          </WhiteContainer>
        </div>
      )}
    </Fragment>
  );
};

export default InviteColleagues;
