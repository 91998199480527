import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import cx from 'classnames';
//redux
import {selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
//functions
import {
  calculateUserAge,
  convertTimeFromSecondsToHours,
  generateLocation,
  setFullName,
  sortVolunteersByName,
} from 'core/functions';
import {tenthListItemSelector} from 'shared/components/Buttons/ButtonUpToTop/util';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  IGetVolunteerByIdResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
//constants
import {
  TABLE_HEADERS,
  TABLE_ITEMS_CLASSNAMES,
  TABLE_HEADER_CLASSNAMES,
  TABLE_ROW_CLASSNAMES,
  ORGANIZATION_TABS,
} from 'core/constants';
//components
import TableMain from 'shared/components/Table/TableMain';
import TableBody from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';
import TableRow from 'shared/components/Table/TableRow';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import Checkbox from 'shared/components/Checkbox';
import PossibleVolunteersActionsCell from 'shared/components/Table/CellRenderers/PossibleVolunteersActionsCell';
import checkboxCellStyles from 'shared/components/Table/CellRenderers/CheckboxVolunteerCell/CheckboxVolunteerCell.module.scss';
import StickyHeader from 'shared/components/Table/TableHead/StickyHeader';
//styles
import styles from './Table.module.scss';
import {localStatusVolunteer} from './data';
import {getCurrentTabFromQuery, getTableHeadStatusConfig} from './helper';

type PossibleVolunteersTableProps = {
  opportunityId: number;
  volunteersArray: Array<IGetVolunteerByIdResponse>;
  opportunityStatus: OPPORTUNITY_STATUSES;
  opportunityType: OPPORTUNITY_TYPES;
  smartGroupVolunteers?: Array<number>;
  setSmartGroupVolunteers?: (checked?: boolean, value?: string) => void;
  setSmartGroupVolunteersAll?: () => void;
  volunteersArrayMembers?: Array<IGetVolunteerByIdResponse>;
  isOrgSuspended?: boolean;
  isApprovalRequired?: boolean;
  showButtons?: boolean;
};

const PossibleVolunteersTable: FC<PossibleVolunteersTableProps> = ({
  volunteersArray,
  opportunityId,
  opportunityStatus,
  smartGroupVolunteers = [],
  setSmartGroupVolunteers = () => {},
  setSmartGroupVolunteersAll = () => {},
  opportunityType,
  volunteersArrayMembers,
  isOrgSuspended,
  isApprovalRequired,
  showButtons,
}: PossibleVolunteersTableProps) => {
  const {search} = useLocation();
  const {t} = useTranslation(['volunteers', 'popup']);

  const orgId = useSelector(selectorGetOrgId);
  const schoolId = useSelector(selectorGetSchoolId);

  const isCheckedAll = useMemo(() => {
    return !!(
      orgId &&
      volunteersArrayMembers &&
      !!smartGroupVolunteers.length &&
      volunteersArrayMembers.length === smartGroupVolunteers.length
    );
  }, [orgId, volunteersArray, smartGroupVolunteers]);

  const tableHeadStatusConfig = useMemo(() => getTableHeadStatusConfig(schoolId), [schoolId]);

  const isPendingTab = getCurrentTabFromQuery(search) === ORGANIZATION_TABS.PENDING;

  return (
    <div className={styles.table}>
      <TableMain>
        <StickyHeader>
          <TableHead
            className={showButtons ? styles.smart_group : styles.past_oppo}
            callChild={
              showButtons
                ? TABLE_HEADER_CLASSNAMES.smart_group_small
                : isPendingTab
                ? TABLE_HEADER_CLASSNAMES.possible_volunteers_pending
                : TABLE_HEADER_CLASSNAMES.possible_volunteers
            }
          >
            {opportunityStatus === OPPORTUNITY_STATUSES.CLOSED && showButtons && (
              <div className={styles.allCheckboxWrapper}>
                <Checkbox
                  changeHandle={setSmartGroupVolunteersAll}
                  defaultChecked={isCheckedAll}
                  disabled={isOrgSuspended}
                />
              </div>
            )}
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.avatar} text={TABLE_HEADERS.avatar} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.fullName} text={TABLE_HEADERS.fullName} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.age} text={TABLE_HEADERS.age} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.gender} text={TABLE_HEADERS.gender} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.location} text={TABLE_HEADERS.location} />
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.completedHours} text={TABLE_HEADERS.completedHours} />
            {!isPendingTab && (
              <TableHeadCell itemClassName={tableHeadStatusConfig.className} text={tableHeadStatusConfig.text} />
            )}
            <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.volunteers_actions} />
          </TableHead>
        </StickyHeader>
        <TableBody>
          {!!volunteersArray.length &&
            sortVolunteersByName([...volunteersArray]).map((volunteer: IGetVolunteerByIdResponse, index: number) => (
              <TableRow
                key={volunteer.id}
                callChild={
                  showButtons
                    ? TABLE_ROW_CLASSNAMES.smart_group_small
                    : isPendingTab
                    ? TABLE_ROW_CLASSNAMES.possible_volunteers_pending
                    : TABLE_ROW_CLASSNAMES.possible_volunteers
                }
                className={cx(showButtons ? styles.smart_group : styles.past_oppo, tenthListItemSelector(index))}
                rejection={volunteer.status === OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT}
              >
                {opportunityStatus === OPPORTUNITY_STATUSES.CLOSED &&
                  showButtons &&
                  setSmartGroupVolunteers &&
                  orgId &&
                  (volunteer?.volunteerSpendTime?.map((org) => +org.organizationId).includes(orgId) ? (
                    <Checkbox
                      value={String(+volunteer.id)}
                      changeHandle={setSmartGroupVolunteers}
                      defaultChecked={!!smartGroupVolunteers?.includes(+volunteer.id)}
                      disabled={isOrgSuspended}
                    />
                  ) : (
                    <div className={checkboxCellStyles.main} />
                  ))}
                <AvatarCell
                  imagePath={volunteer?.imagePath}
                  avatarTitle={t(`popup:profilePicture.${schoolId ? 'student' : 'volunteer'}`)}
                  disablePopupZeroTop
                />
                <FullNameCell
                  firstName={volunteer.firstName}
                  lastName={volunteer.lastName}
                  isShowMemberBadge={
                    !!(orgId && orgId.toString() === volunteer.organizations?.find((i) => +i.id === orgId)?.id)
                  }
                  createDate={volunteer.createDate}
                />
                <CellDefault
                  parentClassName={TABLE_ITEMS_CLASSNAMES.age}
                  text={calculateUserAge(volunteer.birthDate)}
                />
                <CellDefault
                  parentClassName={TABLE_ITEMS_CLASSNAMES.gender}
                  text={volunteer.gender?.genderName.slice(0, 1).toUpperCase() || t('volunteers:noData')}
                />
                <CellDefault
                  parentClassName={TABLE_ITEMS_CLASSNAMES.location}
                  text={(volunteer.address && generateLocation(volunteer.address)) || t('volunteers:noData')}
                />
                <CellDefault
                  parentClassName={TABLE_ITEMS_CLASSNAMES.completedHours}
                  text={convertTimeFromSecondsToHours(volunteer.opportunitySpendTime)}
                />
                {!isPendingTab ? (
                  <CellDefault
                    parentClassName={TABLE_ITEMS_CLASSNAMES.volunteerStatus}
                    text={localStatusVolunteer[volunteer.status] || ''}
                  />
                ) : null}
                {!showButtons && (
                  <PossibleVolunteersActionsCell
                    volunteerId={volunteer.id}
                    opportunityId={opportunityId}
                    opportunityStatus={opportunityStatus}
                    opportunityType={opportunityType}
                    volunteerStatus={volunteer.status}
                    volunteerChatId={volunteer.chatId}
                    volunteerFullName={setFullName(volunteer?.firstName, volunteer?.lastName)}
                    isOrgSuspended={isOrgSuspended}
                    isApprovalRequired={isApprovalRequired}
                  />
                )}
              </TableRow>
            ))}
        </TableBody>
      </TableMain>
    </div>
  );
};

export default PossibleVolunteersTable;
