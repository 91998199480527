import {IInviteUserRequest} from '@joc/api-gateway';
//functions
import {getIsHebrew} from 'core/functions';
//translate
import i18n from 'components/translate';
//validation
import {studentValidationSchema, volunteerValidationSchema} from 'core/validation';

export enum CurrentTab {
  form = 'form',
  list = 'list',
  link = 'link',
  adding = 'adding',
  addByList = 'addByList',
}

export enum UserType {
  Member = 'member',
  Volonteer = 'volonteer',
}

export type ButtonTab = {
  title: string;
  clickHandler: () => void;
  isActive?: boolean;
  isHide?: boolean;
};

export const getIsSetPreferredTab = (currentTab: CurrentTab, isEmpty: boolean, invitedVolunteersLength?: number) =>
  (currentTab === CurrentTab.form && !isEmpty) ||
  !!(currentTab === CurrentTab.list && invitedVolunteersLength) ||
  (currentTab === CurrentTab.adding && !isEmpty) ||
  (currentTab === CurrentTab.addByList && !isEmpty);

export const getIsShowConfirmation = (currentTab: CurrentTab, isEmpty: boolean, invitedVolunteersLength?: number) =>
  currentTab === CurrentTab.link ||
  (currentTab === CurrentTab.list && !invitedVolunteersLength) ||
  (currentTab === CurrentTab.form && isEmpty) ||
  (currentTab === CurrentTab.adding && isEmpty);

export const getErrorMessage = (
  invitedVolunteers: Array<IInviteUserRequest>,
  validationSchema: typeof studentValidationSchema | typeof volunteerValidationSchema
): string | null => {
  const errorMessage = invitedVolunteers?.map((volunteer) => {
    try {
      validationSchema.validateSync(volunteer);
      return null;
    } catch (error) {
      console.log('file: helpers.ts:33  error:', error);
      return error.message as string;
    }
  });

  const errors = errorMessage.filter((value) => value !== null) as Array<string>;

  const filteredErrors = errors.filter((error, index) => errors.indexOf(error) === index);

  const joinValue = getIsHebrew(i18n.language) ? ' ,' : ',  ';

  return !!filteredErrors.length ? filteredErrors.join(joinValue) : null;
};
