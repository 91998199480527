import {Dispatch, FC, SetStateAction, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Store} from 'redux';
//redux
import {deleteOpportunity, getOpportunitiesCount} from 'redux/opportunities-service/action';
import {setError} from 'redux/error-service/action';
import {selectorGetOrgId} from 'redux/organization-service/selector';
//images
import {ReactComponent as ClockSvg} from 'assets/image/clock.svg';
import {ReactComponent as LocationSvg} from 'assets/image/location.svg';
import {ReactComponent as ManualOppo} from 'assets/icons/heart.svg';
//hooks
import {useTableActionsPopup} from 'hooks/useTableActionsPopup';
import useThunkDispatch from 'hooks/useThunkDispatch';
//types
import {
  IOpportunityResponse,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  RECURRENCE_POSSIBLE_UPDATE,
} from '@joc/api-gateway';
//functions
import {convertTimeToHoursAndMinutes, generateLocation, getDurationDetailsWithDate} from 'core/functions';
import {getAdminOppo, getTimeOppo} from './helper';
//components
import PossibleVolunteersItem from 'shared/components/PossibleVolunteersItem';
import ManualOppoInscription from 'shared/components/Table/ManualOppoInscription';
import {TableActionsPopups} from '../../../TableActionsPopup/TableActionsPopups';
//styles
import styles from './ReminderTapeItem.module.scss';

type ReminderTapeItemPropsType = {
  opportunity: IOpportunityResponse;
  setIsPreviewOpen: Dispatch<SetStateAction<boolean>>;
};

const ReminderTapeItem: FC<ReminderTapeItemPropsType> = ({opportunity, setIsPreviewOpen}) => {
  const {t} = useTranslation(['common']);
  const dispatch = useThunkDispatch();

  const {isVirtual, address} = opportunity;
  const {
    popupsState,
    popupsActions: {
      handleOpenManualPopup,
      handleOpenDeleteTypeModal,
      handleCloseDeleteTypeModal,
      handleOpenUpdatePopup,
    },
  } = useTableActionsPopup(() => {});
  const orgId = useSelector((store: Store) => selectorGetOrgId(store as any));

  const isAdmin = useMemo(() => getAdminOppo(opportunity), [opportunity]);
  const isManualOpportunity = useMemo(() => opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL, [opportunity]);
  const oppoTime = useMemo(() => getTimeOppo(opportunity, isManualOpportunity), [opportunity]);
  const isShowLocation = useMemo(() => !(isVirtual || !address), [isVirtual, address]);

  const handleDeleteOppo = async (updateType?: RECURRENCE_POSSIBLE_UPDATE) => {
    if (opportunity?.id) {
      try {
        await dispatch(deleteOpportunity(opportunity.id, updateType));
        if (orgId) await dispatch(getOpportunitiesCount(orgId.toString()));
        if (!!updateType) window.location.reload();
        popupsState.confirmation.popupSetState(false);
      } catch (error) {
        dispatch(setError(error?.response?.message || error.message, error.code));
      }
    }
  };

  const handleOpenConfirmModal = () => {
    !!opportunity?.repeatableOpportunity ? handleOpenDeleteTypeModal() : handleDeleteOppo();
  };

  const handleOpenModal = () => {
    opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL ? handleOpenManualPopup() : handleOpenUpdatePopup();
  };

  return (
    <div className={styles.container}>
      <div className={styles.title} onClick={handleOpenModal}>
        {isManualOpportunity && (
          <div className={styles.mark__wrapper}>
            <div className={styles.manual__mark}>
              <ManualOppo height={20} width={20} />
            </div>
            {isAdmin && <p className={styles.admin__mark}>{t('common:admin')}</p>}
          </div>
        )}
        {opportunity.opportunityName}
      </div>
      <div className={styles.time}>
        <ClockSvg />
        <span>{oppoTime}</span>
      </div>
      {isShowLocation && (
        <div className={styles.location}>
          <LocationSvg />
          <span>{opportunity.address && generateLocation(opportunity.address)}</span>
        </div>
      )}
      <div>
        {!!opportunity?.volunteers?.length && (
          <PossibleVolunteersItem
            opportunity={opportunity}
            volunteers={opportunity.volunteers}
            opportunityId={opportunity.id}
            isApprovalRequired={opportunity.isApprovalRequired}
            opportunityName={opportunity.opportunityName}
            setIsPreviewOpen={setIsPreviewOpen}
            opportunityStatus={opportunity.status}
            opportunityType={opportunity.opportunityType}
            actionsDisable={opportunity.status === OPPORTUNITY_STATUSES.CLOSED}
          />
        )}
      </div>

      <TableActionsPopups
        opportunity={opportunity}
        popupsState={popupsState}
        closeDeleteTypeModalClickHandler={handleCloseDeleteTypeModal}
        deleteConfirmClickHandler={handleDeleteOppo}
        popupConfirmationClickHandler={handleOpenConfirmModal}
      />
    </div>
  );
};

export default ReminderTapeItem;
