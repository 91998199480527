import {FC, useMemo} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
//functions
import {setFullName} from 'core/functions';
//components
import VolunteerBadges from '../../Badges/VolunteerBadges';
//styles
import styles from './FullNameCell.module.scss';
import defaultStyles from '../CellDefault.module.scss';

type FullNameCellProps = {
  firstName: string | undefined;
  lastName: string | undefined;
  createDate?: Date;
  disableTextStyle?: boolean;
  isShowMemberBadge?: boolean;
  parentClassName?: string;
};

const FullNameCell: FC<FullNameCellProps> = ({
  firstName,
  lastName,
  createDate,
  disableTextStyle,
  isShowMemberBadge,
  parentClassName,
}: FullNameCellProps) => {
  const {
    i18n: {language},
  } = useTranslation();

  const fullName = useMemo(() => setFullName(firstName, lastName), [firstName, lastName]);

  return (
    <div className={cx(styles.fullName, defaultStyles.main, parentClassName)}>
      <div className={styles.fullName__content}>
        <VolunteerBadges createDate={createDate} isShowMemberBadge={isShowMemberBadge} language={language} />
        <span className={cx(!disableTextStyle && styles.text)} title={fullName}>
          <span>
            {fullName.length > 30 ? fullName.substring(0, 30) : fullName}
            {fullName.length > 30 ? '...' : ''}
          </span>
        </span>
      </div>
    </div>
  );
};

export default FullNameCell;
