import {FC, useEffect, useLayoutEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, useFormikContext} from 'formik';
import qs from 'qs';
//form configuration
import {EditVolunteerFormValues} from '../formConfiguration';
//hooks
import useEffectNoFirstMount from 'hooks/useEffectNoFirstMount';
//helpers
import {removeErrorBorderClassById, scrollToElementById} from './helpers';
//components
import Input from 'shared/inputs/Input';
import Loader from 'shared/components/Loader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import InputPhone from 'shared/components/FormInputs/InputPhone';
import GoogleApiAutoCompleat from 'shared/components/GoogleApiAutoComplete';
import {DatePicker} from 'shared/components/Pickers/NewUI/DatePicker';
import GenderSelect from 'shared/components/SelectDropdown/GenderSelect';
import UploadPhoto from 'components/Volunteer/UploadPhoto';
//styles
import styles from './EditVolunteerMainForm.module.scss';

type EditVolunteerMainFormParentsProps = {
  isVolunteerSuspended: boolean;
  isLoading: boolean;
};

const EditVolunteerMainForm: FC<EditVolunteerMainFormParentsProps> = ({isVolunteerSuspended, isLoading}) => {
  const {values, dirty, isValid} = useFormikContext<EditVolunteerFormValues>();

  const {t} = useTranslation(['form', 'inputs', 'buttons']);

  const isDirty = useMemo(() => dirty && values.address !== null, [values.address, dirty]);

  //switch from the AddBirthdayPopup modal window, to set the priority birthDate
  useEffect(() => {
    const {ref} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    if (ref) scrollToElementById(ref as string);
  }, []);

  useEffectNoFirstMount(() => {
    const {ref} = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    if (ref) removeErrorBorderClassById(ref as string);
  }, [values.birthDate]);

  return (
    <Form className={styles.form} title={isVolunteerSuspended ? t('form:editVolunteer.formTitle') : ''}>
      <Field
        name="imagePath"
        disabled={isVolunteerSuspended}
        parentClassName={styles.upload_photo}
        component={UploadPhoto}
      />
      <Field
        name="firstName"
        placeholder={t('form:user.firstName')}
        disabled={isVolunteerSuspended}
        isDisabledMargin
        isSmallHeight
        component={Input}
      />
      <Field
        name="lastName"
        placeholder={t('form:user.lastName')}
        disabled={isVolunteerSuspended}
        isDisabledMargin
        isSmallHeight
        component={Input}
      />
      <Field name="phoneNumber" disabled={isVolunteerSuspended} isDisabledMargin isSmallHeight component={InputPhone} />
      <Field name="email" placeholder={t('form:email')} isDisabledMargin disabled isSmallHeight component={Input} />
      <div id="birthDate">
        <Field
          name="birthDate"
          placeholder={t('inputs:placeholders.dob')}
          disabled={isVolunteerSuspended}
          parentClassName={styles.birthDate}
          availablePast
          disableFuture
          component={DatePicker}
          isDisabledMargin
          isSmallHeight
        />
      </div>
      <Field
        name="gender"
        placeholder={t('inputs:placeholders.gender')}
        disabled={isVolunteerSuspended}
        isDisabledMargin
        isSmallHeight
        component={GenderSelect}
      />
      <Field
        name="address"
        disabled={isVolunteerSuspended}
        isDisabledMargin
        isSmallHeight
        isAddressErrorText
        isInputValidation
        component={GoogleApiAutoCompleat}
      />
      <Field name="studentId" placeholder={t('inputs:placeholders.idNumber')} component={Input} />
      {isLoading ? (
        <Loader loadProps={{disableCenterStyle: true}} />
      ) : (
        <ButtonDefault
          submitType
          classList={['primary', 'md']}
          title={t('buttons:button.submit')}
          disabled={!isDirty || isVolunteerSuspended || !isValid}
        />
      )}
    </Form>
  );
};

export default EditVolunteerMainForm;
