import {FC} from 'react';
import {useSelector} from 'react-redux';
//redux
import {Store} from 'redux/root';
//types
import {IInviteUserRequest} from '@joc/api-gateway';
//constants
import {TABLE_HEADERS, TABLE_HEADER_CLASSNAMES, TABLE_ITEMS_CLASSNAMES, TABLE_ROW_CLASSNAMES} from 'core/constants';
import TABLE_STICKY_HEADER_CLASSNAMES from 'core/constants/TABLE_STICKY_HEADER_CLASSNAMES';
//components
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import TableBody from 'shared/components/Table/TableBody';
import TableHead from 'shared/components/Table/TableHead';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableRow from 'shared/components/Table/TableRow';
import StickyHeader from 'shared/components/Table/TableHead/StickyHeader';

type InvitedVolunteersTableParentProps = {
  volunteers: Array<IInviteUserRequest>;
  overTariffStartIndex?: number;
};

const InvitedVolunteersTable: FC<InvitedVolunteersTableParentProps> = ({volunteers, overTariffStartIndex}) => {
  const schoolId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.schoolId));

  return (
    <TableMain>
      <StickyHeader headerFor={TABLE_STICKY_HEADER_CLASSNAMES.invitedUsers}>
        <TableHead callChild={!!schoolId ? TABLE_HEADER_CLASSNAMES.inviteStudents : TABLE_HEADER_CLASSNAMES.members}>
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.fullName} text={TABLE_HEADERS.fullName} />
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.email} text={TABLE_HEADERS.email} />
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.phone} text={TABLE_HEADERS.phoneNumber} />
          <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.completedHours} text={TABLE_HEADERS.timeSpend} />
          {!!schoolId && <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.phone} text={TABLE_HEADERS.gradeId} />}
        </TableHead>
      </StickyHeader>
      <TableBody>
        {volunteers.map((volunteer: IInviteUserRequest, index: number) => (
          <TableRow
            callChild={!!schoolId ? TABLE_ROW_CLASSNAMES.inviteStudents : TABLE_ROW_CLASSNAMES.members}
            overtariff={!!(overTariffStartIndex && overTariffStartIndex <= index)}
            key={`${volunteer.email}`}
          >
            <FullNameCell firstName={volunteer.firstName} lastName={volunteer.lastName} />
            <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.email} text={volunteer.email} />
            <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.phone} text={volunteer.phoneNumber} />
            <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.phone} text={volunteer.timeSpend} />
            {!!schoolId && <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.phone} text={volunteer.gradeId} />}
          </TableRow>
        ))}
      </TableBody>
    </TableMain>
  );
};

export default InvitedVolunteersTable;
