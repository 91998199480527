import {FC, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
//translation
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {selectorGetOrgActiveStatus} from 'redux/organization-service/selector';
//types
import {ORGANISATION_ACTIVE_STATUS} from '@joc/api-gateway';
//components
import MarketingListVideo from 'components/Organization/Marketing/MarketingListVideo';
import PopupUploadVideo from 'components/Organization/Marketing/PopupUploadVideo';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';
import PopupContainer from 'shared/components/PopupContainer';
//styles
import styles from './Marketing.module.scss';

const MarketingPage: FC<MarketingPageProps> = ({orgStatus}) => {
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);

  const {t} = useTranslation(['marketing', 'popup']);

  const isSuspended = orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED;

  return (
    <div className={styles.container}>
      <div className={styles.block_title}>
        <h1 className="title">{t('marketing:title')}</h1>
        <div className={styles.block_title_action_button}>
          <ButtonDefault
            title={t('marketing:uploadMedia')}
            classList={['primary', 'extra_md']}
            clickHandler={() => setIsShowPopup(true)}
            disabled={isSuspended}
          />
        </div>
      </div>
      <MarketingListVideo setIsShowPopup={setIsShowPopup} />
      {isShowPopup && (
        <PopupContainer setIsShowPopup={setIsShowPopup}>
          <div className={styles.popupTitle}>{t('popup:marketing.uploadStatus')}</div>
          <PopupUploadVideo />
        </PopupContainer>
      )}
    </div>
  );
};

const mapStateToProps = (store: Store) => ({
  orgStatus: selectorGetOrgActiveStatus(store),
});

const connector = connect(mapStateToProps);

type MarketingPageProps = ConnectedProps<typeof connector>;

export default connector(MarketingPage);
