import {Dispatch, FC, SetStateAction, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Formik} from 'formik';
//types
import {
  ICreateOpportunityRequest,
  IOpportunityResponse,
  IUpdateOpportunityRequest,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
} from '@joc/api-gateway';
//redux
import {Store} from 'redux/root';
import {updateOpportunity} from 'redux/opportunities-service/action';
import {selectorGetUserId} from 'redux/user-service/selector';
import {selectorGetSmartGroupsAll} from 'redux/smartGroups-service/selector';
//validation
import {
  opportunityFormValidationSchemaOnUpdate,
  opportunityFormValidationSchemaWithAddressOnUpdate,
} from 'core/validation';
//types
import {IUpdateOpportunityInitialValues, OpportunityInitialValuesType} from 'core/types';
//hooks
import useThunkDispatch from 'hooks/useThunkDispatch';
//helpers
import {
  getDate,
  getFormattedDate,
  getFormattedRepeatedInfo,
  getIsOpportunityClockDisabled,
  getPopUpdateInitialValues,
  getRecurrencePossibleUpdate,
} from './helpers';
//components
import ResponseFailure from 'shared/components/ResponseFailure';
import PopupCongrats from '../PopupCongrats';
import OpportunityMainForm from '../../OpportunityForm/OpportunityMainForm';
//styles
import styles from '../../OpportunityForm/OpportunityForm.module.scss';

type OppoFormInitialValuesType = IUpdateOpportunityRequest | ICreateOpportunityRequest;
/**
 * TODO: need to check why the starting values were recalculated
 */
type OppoFormInitialValuesTypeSecond = OpportunityInitialValuesType | IUpdateOpportunityInitialValues;

interface IPopupUpdateProps {
  createMode?: boolean;
  opportunityData: IOpportunityResponse;
  setIsShowUpdateOpportunityPopup: Dispatch<SetStateAction<boolean>>;
  setIsShowCreateOpportunityPopup?: Dispatch<SetStateAction<boolean>>;
  setOpportunityData?: Dispatch<SetStateAction<IOpportunityResponse | undefined>>;
}

const PopupUpdate: FC<IPopupUpdateProps> = ({
  opportunityData,
  setOpportunityData,
  setIsShowUpdateOpportunityPopup,
  setIsShowCreateOpportunityPopup,
}) => {
  const {t} = useTranslation(['errors', 'popup']);
  const thunkDispatch = useThunkDispatch();

  const userId = useSelector((store: Store) => selectorGetUserId(store));
  const schoolId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.schoolId));
  const smartGroupsAll = useSelector(selectorGetSmartGroupsAll);
  const oppoFormInitialValues: OppoFormInitialValuesType = getPopUpdateInitialValues(opportunityData, smartGroupsAll);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isPrivateOpportunity, setIsPrivateOpportunity] = useState(
    !!schoolId || oppoFormInitialValues.opportunityType === OPPORTUNITY_TYPES.PRIVATE
  );
  const [isVirtualOpportunity, setIsVirtualOpportunity] = useState(oppoFormInitialValues?.isVirtual);
  const [manualTimeTracking, setManualTimeTracking] = useState(oppoFormInitialValues?.manualTimeTracking);
  const [isVisibleVolunteerLimit, setIsVisibleVolunteerLimit] = useState<boolean>(!!opportunityData.volunteersMaxCount);
  const [isVisibleHashtagField, setIsVisibleHashtagField] = useState<boolean>(!!opportunityData.tagId);
  const isOpportunityClockDisabled: boolean = getIsOpportunityClockDisabled(opportunityData);

  const submitClickHandler = async (
    // TODO: correct type
    values: any
  ): Promise<void> => {
    setIsLoading(true);
    //TODO create function in helpers for converting all these values and just invoke this one here
    values.gender = values.gender && +values.gender;
    const smartGroupId = values.smartGroupId && +values.smartGroupId;
    const formatedStartDate = getFormattedDate(values.startDate, values.startTime);
    const formatedEndDate = getFormattedDate(values.startDate, values.endTime);
    const volunteersMaxCount = values.volunteersMaxCount === '' ? null : values.volunteersMaxCount;
    const tagId = !values.tagId ? null : Number(values.tagId);

    const startDate = getDate(formatedStartDate);
    const endDate = getDate(formatedEndDate);

    const repeatableOpportunity = getFormattedRepeatedInfo(values.repeatableOpportunity);

    const recurrencePossibleUpdate = getRecurrencePossibleUpdate(
      values.repeatableOpportunity,
      values.recurrencePossibleUpdate
    );

    const convertedValues: IUpdateOpportunityRequest = {
      ...(values as IUpdateOpportunityRequest),
      smartGroupId,
      startDate,
      endDate,
      tagId,
      repeatableOpportunity,
      recurrencePossibleUpdate,
      volunteersMaxCount,
    } as IUpdateOpportunityRequest;

    const body = document.createElement('body');
    body.innerHTML = values.opportunityDescription;

    try {
      const updatedOpportunity = await thunkDispatch(
        updateOpportunity(opportunityData.id, {...convertedValues, opportunityDescription: body.outerHTML})
      );
      if (setOpportunityData) setOpportunityData(updatedOpportunity);
      setIsLoading(false);
      setIsSubmitted(true);
      if (!!repeatableOpportunity) window.location.reload();
    } catch (error) {
      setIsLoading(false);
      setError(error?.response?.message || error.message);
    }
  };

  const onSubmitClickHandler = (values: OppoFormInitialValuesTypeSecond) => {
    if (values.gender && values.gender === 3) values.gender = undefined;
    submitClickHandler(values);
  };

  const isOppoMainFormValidationSchema = useMemo(
    () =>
      isVirtualOpportunity
        ? opportunityFormValidationSchemaOnUpdate
        : opportunityFormValidationSchemaWithAddressOnUpdate,
    [isVirtualOpportunity]
  );

  if (error)
    return (
      <ResponseFailure styleTable message={error || t('errors:errorPlease')} buttonClickHandler={() => setError('')} />
    );
  if (isSubmitted)
    return (
      <PopupCongrats
        setIsShowParentPopup={setIsShowCreateOpportunityPopup}
        setIsShowPopup={setIsShowUpdateOpportunityPopup}
        setIsSubmited={setIsSubmitted}
        isUpdateMode
      />
    );
  return (
    <>
      <h2 className="title">{t('popup:opportunity.updateTitle')}</h2>
      <div className={styles.form__container}>
        <Formik
          enableReinitialize
          initialValues={oppoFormInitialValues as OppoFormInitialValuesTypeSecond}
          validationSchema={isOppoMainFormValidationSchema}
          onSubmit={onSubmitClickHandler}
        >
          <OpportunityMainForm
            isEdit
            schoolId={schoolId}
            isLoading={isLoading}
            opportunityData={opportunityData}
            manualTimeTracking={manualTimeTracking}
            isPrivateOpportunity={isPrivateOpportunity}
            isVirtualOpportunity={isVirtualOpportunity}
            isVisibleHashtagField={isVisibleHashtagField}
            setManualTimeTracking={setManualTimeTracking}
            setIsVirtualOpportunity={setIsVirtualOpportunity}
            setIsPrivateOpportunity={setIsPrivateOpportunity}
            isVisibleVolunteerLimit={isVisibleVolunteerLimit}
            setIsVisibleHashtagField={setIsVisibleHashtagField}
            setIsVisibleVolunteerLimit={setIsVisibleVolunteerLimit}
            isOpportunityTimeDisable={isOpportunityClockDisabled}
            isOpportunityDateDisable={isOpportunityClockDisabled}
            isRepeated={!!oppoFormInitialValues.repeatableOpportunity}
            statusClose={opportunityData.status === OPPORTUNITY_STATUSES.CLOSED}
            isManual={opportunityData.opportunityType === OPPORTUNITY_TYPES.MANUAL}
          />
        </Formik>
      </div>
    </>
  );
};

export default PopupUpdate;
