import {FC, ReactNode, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import cx from 'classnames';
//redux
import {selectorGetError} from 'redux/error-service/selector';
import {Store} from 'redux/root';
//helpers
import {setEachMessageToNewLine} from './helpers';
//constants
import {urls} from 'core/appUrls';
import {LOGIN_ERROR} from 'core/types';
import {ACCESS_DENIED_ERROR_TIME} from 'core/constants/ACCESS_DENIED_ERROR_TIME';
//components
import Appeal from '../Appeal/DefaultRenderer';
import ButtonDefault from '../Buttons/ButtonsDefault';
//styles
import styles from './ResponseFailure.module.scss';
import {ManyRequestsMessage} from './ManyRequestsMessage';

type ResponseFailureParentProps = {
  message: Required<NonNullable<ReactNode>>;
  buttonTitle?: string;
  buttonClickHandler?: () => void;
  styleTable?: boolean;
  styleThink?: boolean;
  styleCongrats?: boolean;
  styleEmptyChesed?: boolean;
  styleContact?: boolean;
  buttonUnshow?: boolean;
  isHeightVisibility?: boolean;
  title?: string;
  isVertical?: boolean;
  isReloginDisabled?: boolean;
  isFullHeight?: boolean;
  isVolunteersLimit?: boolean;
  errorType?: LOGIN_ERROR;
  parentClassName?: string;
  eachMessageToNewLine?: boolean;
};

const ResponseFailure: FC<ResponseFailureProps> = ({
  message,
  buttonTitle,
  styleTable,
  styleThink,
  styleCongrats,
  styleEmptyChesed,
  styleContact,
  buttonClickHandler,
  error,
  buttonUnshow,
  title,
  isVertical,
  isReloginDisabled,
  isFullHeight,
  isVolunteersLimit,
  errorType,
  parentClassName,
  eachMessageToNewLine,
}) => {
  const [timeCount, setTimeCount] = useState(ACCESS_DENIED_ERROR_TIME / 1000);

  const {t} = useTranslation(['errors', 'buttons', 'messages', 'common']);

  useEffect(() => {
    if (!isReloginDisabled) {
      if (error.code === 403 && timeCount > 0) {
        setTimeout(() => {
          setTimeCount(timeCount - 1);
        }, 1000);
      }
    }
  }, [timeCount]);

  const errorContent = useMemo(() => {
    if (eachMessageToNewLine) return setEachMessageToNewLine(String(message));

    if (isVertical && typeof message === 'string') {
      const arrayMessage = message.split(',');

      return arrayMessage.map((err) => <span>{err}</span>);
    }

    if (!isReloginDisabled && error.code === 403) {
      return `${t('errors:youNeedToRelogin')} ${timeCount} ${t('messages:seconds')}`;
    }

    return message;
  }, [isVertical, message]);

  const isButtonHidden = (!isReloginDisabled && error.code === 403) || buttonUnshow;

  const isErrorManyRequests = useMemo(() => errorType === LOGIN_ERROR.TOO_MANY_REQUESTS, [errorType]);

  return (
    <Appeal
      styleTable={styleTable}
      styleThink={styleThink}
      styleCongrats={styleCongrats}
      styleEmptyChesed={styleEmptyChesed}
      styleContact={styleContact}
      isFullHeight={isFullHeight}
      isHeightDisable
    >
      <div className={cx(styles.content, parentClassName, isErrorManyRequests && styles.content__message_many_request)}>
        <span className="title">{!!title ? title : t('errors:sorry')}</span>

        <span
          className={cx(styles.content__message, {
            [styles.content__message__vertical]: isVertical,
            [styles.content__message_limit]: isVolunteersLimit,
          })}
        >
          {isErrorManyRequests ? <ManyRequestsMessage /> : errorContent}
        </span>

        {!isButtonHidden && (
          <ButtonDefault
            classList={['primary', 'md']}
            title={buttonTitle || t('buttons:button.tryAgain')}
            clickHandler={buttonClickHandler}
          />
        )}
      </div>
    </Appeal>
  );
};

const mapStateToProps = (store: Store) => ({
  error: selectorGetError(store),
});

const connector = connect(mapStateToProps);

type ResponseFailureProps = ConnectedProps<typeof connector> & ResponseFailureParentProps;

export default connector(ResponseFailure);
