import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState, MouseEvent} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';
import * as XLSX from 'xlsx';
//redux
import {Store} from 'redux/root';
import {notAcceptedInvitesCount, resendInvites} from 'redux/invites-service/action';
import {SET_ERROR} from 'redux/actions-types';
import {selectorGetSchoolId} from 'redux/organization-service/selector';
import {useDispatch, useSelector} from 'react-redux';
//validation
import {volunteerValidationSchema} from 'core/validation';
//constants
import {UserInfoKeyNamesInExcelSheet, userInfoKeys} from './constants';
//types
import {IInviteUserRequest} from '@joc/api-gateway';
import {InvitedUserWithRole} from 'core/types';
//components
import PopupCongrats from 'components/Organization/Volunteers/VolunteersPopups/PopupCongrats';
//styles
import styles from './InputImportList.module.scss';

type InputImportListProps = {
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setStateValue?: Dispatch<SetStateAction<Array<IInviteUserRequest> | undefined>>;
  fieldName?: string;
  listLength?: number;
  invitedUserType: string;
  exampleFilePath: string;
  addByList?: boolean;
};

const InputImportList: FC<InputImportListProps> = ({
  setFieldValue,
  setStateValue,
  listLength,
  invitedUserType,
  fieldName,
  exampleFilePath,
  addByList,
}: InputImportListProps) => {
  const [file, setFile] = useState<File>();

  const dispatch = useDispatch();

  const {invitesCount, response} = useSelector((store: Store) => store.invitesRedux);
  const schoolId = useSelector(selectorGetSchoolId);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const {t} = useTranslation(['form', 'inviteColleagues']);

  useEffect(() => {
    getNotAcceptedVolunteersCount();
  }, []);

  useEffect(() => {
    file && parseFile(file);
  }, [file]);

  const checkIsValidItem = async (item: any): Promise<boolean> => {
    const isValid = await volunteerValidationSchema.validate(item).catch((err) => {
      err.name; // => 'ValidationError'
      err.errors; // => [{ key: 'field_too_short', values: { min: 18 } }]
      console.error(err);
      console.log('file: index.tsx:61  err:', err);
    });
    return !!isValid;
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.files && setFile(Array.from(e.target.files)[0]);
  };

  const parseFile = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      const excelData = XLSX.read(data, {type: 'binary'});
      const sheetName = excelData.SheetNames[0];
      const sheet = excelData.Sheets[sheetName];

      const getParsedData = (sheet: XLSX.WorkSheet) => {
        const rowsArray = XLSX.utils.sheet_to_json(sheet, {header: 1}) as Array<Array<string>>;
        const rowIndex = rowsArray.findIndex((row) => row.includes('First Name'));
        const ref = sheet['!ref']!;

        let range = ref;

        if (rowIndex > 0) {
          const [refStart, refEnd] = ref.split(':');
          const [refStartX] = refStart.split('');
          const newRefStart = `${refStartX}${rowIndex + 1}`;
          range = `${newRefStart}:${refEnd}`;
        }

        return XLSX.utils.sheet_to_json(sheet, {range, defval: '', blankrows: false}) as Array<InvitedUserWithRole>;
      };

      // const cellsToDelete = 'ABCDEFGHIJK'.split('').map((cell) => `${cell}1`);
      // cellsToDelete.forEach((cell) => delete sheet[cell]);

      // const newRef = sheet['!ref']
      //   ?.split('')
      //   .map((el, index) => (index === 1 ? '2' : el))
      //   .join('');
      // sheet['!ref'] = newRef;

      // let parsedData: Array<InvitedUserWithRole> = XLSX.utils.sheet_to_json(sheet, {
      //   header: 1,
      //   defval: '',
      //   blankrows: false,
      // });
      let parsedData = getParsedData(sheet);
      const normalizeParsedData = parsedData.map((parsedUserValuesObj) => {
        let normalizedUserValuesObj = {} as InvitedUserWithRole;

        Object.keys(parsedUserValuesObj).forEach((key) => {
          const normalizeKeyName = key
            .split(' ')
            .filter((key) => key !== '-')
            .map((word, idx) => {
              word = word.toLowerCase();
              if (idx !== 0) word = word[0].toUpperCase() + word.slice(1);
              return word;
            })
            .join('');

          normalizedUserValuesObj = {
            ...normalizedUserValuesObj,
            [normalizeKeyName]: parsedUserValuesObj[key as keyof InvitedUserWithRole],
          };
        });

        return normalizedUserValuesObj;
      });

      parsedData = normalizeParsedData.map((userValuesObj) => {
        let user = {} as InvitedUserWithRole;

        userInfoKeys.forEach((key) => {
          // @ts-ignore
          if (userValuesObj[UserInfoKeyNamesInExcelSheet[key] as keyof InvitedUserWithRole])
            // @ts-ignore
            user = {...user, [key]: userValuesObj[UserInfoKeyNamesInExcelSheet[key]]};
        });
        if (user.phoneNumber)
          user.phoneNumber = user.phoneNumber[0] === '+' ? user.phoneNumber : `+${user.phoneNumber}`;
        if (user.genderId?.toString().toLowerCase()[0] === 'f') {
          user.genderId = 2;
        }
        if (user.genderId?.toString().toLowerCase()[0] === 'm') {
          user.genderId = 1;
        }
        if (user.studentId) {
          user.studentId = user.studentId?.toString();
        }
        if (!schoolId) {
          delete user.gradeId;
        }
        return user;
      });
      (async () => {
        try {
          parsedData.forEach(async (item) => await checkIsValidItem(item));
        } catch (error) {
          throw new Error(error);
        }
      })();

      if (setFieldValue && fieldName) setFieldValue(fieldName, parsedData);
      if (setStateValue) setStateValue(parsedData);
    };

    reader.readAsBinaryString(file);
  };

  const resendInvitesVolunteer = async (e: MouseEvent) => {
    e.preventDefault();
    try {
      await dispatch(resendInvites(undefined));
    } catch (error) {
      // setCustomError(error?.response?.message || error.message);
      dispatch({type: SET_ERROR, payload: {state: true, message: error.message}});
    }
  };

  const getNotAcceptedVolunteersCount = async () => {
    try {
      const response = await dispatch(notAcceptedInvitesCount(undefined));
      return response;
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error.message}});
    }
  };

  const handleUploadClick = async () => {
    setFile(undefined);
    setStateValue && setStateValue([]);
    inputRef.current?.click();
  };

  return (
    <>
      <div className={styles.upload}>
        <span className={styles.upload__text} onClick={handleUploadClick}>
          {t('form:listInput.importAList')}
        </span>
        <input
          ref={inputRef}
          id="upload"
          type="file"
          accept=".csv, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onInput={handleUpload}
          // DON'T DROP BELOW!!!
          // @ts-ignore
          // eslint-disable-next-line no-return-assign
          onClick={(e) => (e.target.value = null)}
        />
        <a className={cx(styles.upload__text, styles.downloadTemplate, 'link')} href={exampleFilePath} download>
          {t('form:listInput.downloadListTemplate')}
        </a>

        {!!listLength ? (
          <div className={styles.upload__info}>
            {`${t('inviteColleagues:detected')} ${listLength} ${
              listLength > 1
                ? `${
                    invitedUserType === 'colleague'
                      ? t('inviteColleagues:colleagues')
                      : invitedUserType === 'student'
                      ? t('inviteColleagues:students')
                      : t('inviteColleagues:volunteers')
                  }`
                : `${
                    invitedUserType === 'colleague'
                      ? t('inviteColleagues:colleague')
                      : invitedUserType === 'student'
                      ? t('inviteColleagues:student')
                      : t('inviteColleagues:volunteer')
                  }`
            }`}
          </div>
        ) : (
          !addByList &&
          !!invitesCount.invitesCount && (
            <div className={cx(styles.upload__text, styles.downloadTemplate)} onClick={resendInvitesVolunteer}>
              {t('form:listInput.resendInvites')}
            </div>
          )
        )}
        {response && <PopupCongrats setIsShowPopup={() => {}} resendInvites />}
      </div>
    </>
  );
};

export default InputImportList;
