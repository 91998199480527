import {Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {connect, ConnectedProps, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import qs from 'qs';
import cx from 'classnames';
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock';
//i18n
import {useTranslation} from 'react-i18next';
import i18n from 'components/translate';
//redux
import {Store} from 'redux/root';
import {resetError} from 'redux/error-service/action';
import {resetVolunteers} from 'redux/volunteer-service/actions';
//types
import {
  IBasicVolunteerResponse,
  IFullTextSearchVolunteers,
  ISearchVolunteersSort,
  ORGANISATION_GENERAL_TYPES,
  SEARCH_VOLUNTEER_POSSIBLE_SEARCH,
  SEARCH_VOLUNTEER_POSSIBLE_SORT,
  VOLUNTEER_ACTIVE_STATUS,
} from '@joc/api-gateway';
import {PossibleSortBy} from 'shared/components/Table/TableHead/TableHeadCell/helpers';
//constants
import {INITIAL_PAGINATION, TABLE_ITEMS_CLASSNAMES, TABLE_HEADERS} from 'core/constants';
//functions
import {
  calculateUserAge,
  convertTimeFromSecondsToHours,
  generateGenderName,
  generateLocation,
  getVolunteerTotalSpendTimeByCurrentOrg,
  setSearchOrSortQueryString,
} from 'core/functions';
import {tenthListItemSelector} from 'shared/components/Buttons/ButtonUpToTop/util';
//helpers
import {getHeaderSortQueryParams} from 'components/Organization/Volunteers/helpers';
//components
import TableHead from 'shared/components/Table/TableHead';
import TableRow from 'shared/components/Table/TableRow';
import TableHeadCell from 'shared/components/Table/TableHead/TableHeadCell';
import TableMain from 'shared/components/Table/TableMain';
import TableBody from 'shared/components/Table/TableBody';
import FullNameCell from 'shared/components/Table/CellRenderers/FullNameCell';
import AvatarCell from 'shared/components/Table/CellRenderers/AvatarCell';
import CellDefault from 'shared/components/Table/CellRenderers/CellDefault';
import CheckboxVolunteerCell from 'shared/components/Table/CellRenderers/CheckboxVolunteerCell';
import TableMenu from 'components/Organization/TableMenu';
import ResponseFailure from 'shared/components/ResponseFailure';
import {VolunteersPageContext, VolunteersPageContextType} from 'pages/Organization/Volunteers';
import Loader from 'shared/components/Loader';
import Observer from 'shared/components/Observer';
import Actions from '../Actions';
import StickyHeader from 'shared/components/Table/TableHead/StickyHeader';
import TableHeadCellWithSort from 'shared/components/Table/TableHead/TableHeadCell/TableHeadCellWithSort';

//styles
import styles from './index.module.scss';

const errorMessage = i18n.t('errors:couldntFindVolunteers');

type SelectableVolunteersTableParentProps = {
  isEditSmartGroup?: boolean;
  setIsShowSelectableVolunteersTable: Dispatch<SetStateAction<boolean>>;
  checkedVolunteersId: Array<number>;
  setCheckedVolunteersId: Dispatch<SetStateAction<Array<number>>>;
};

const SelectableVolunteersTable: FC<SelectableVolunteersTableProps> = ({
  isEditSmartGroup = false,
  setIsShowSelectableVolunteersTable,
  checkedVolunteersId,
  setCheckedVolunteersId,
  resetError,
  resetVolunteers,
}) => {
  const [isCloseTable, setIsCloseTable] = useState(false);
  const [currentCheckedVolunteersId, setCurrentCheckedVolunteersId] = useState<Array<number>>(checkedVolunteersId);

  const volunteersRecords = useSelector((store: Store) => store.volunteersRedux.volunteers.records);
  const volunteersTotal = useSelector((store: Store) => store.volunteersRedux.volunteers.total);
  const error = useSelector((store: Store) => store.errorRedux.error);
  const orgId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.id));
  const schoolId = useSelector((store: Store) => Number(store.organizationRedux.organizationInfo?.schoolId));
  const orgGeneralType = useSelector(
    (store: Store) => store.organizationRedux.organizationInfo?.organisationGeneralType
  );

  const {t} = useTranslation(['volunteers', 'buttons', 'errors', 'popup']);

  const tableTitle = useMemo(
    (): string =>
      t(
        orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
          ? 'volunteers:subtitleIncludeStudents'
          : 'volunteers:subtitleIncludeVolunteers'
      ),
    [orgGeneralType]
  );

  const studentGrade = useCallback(
    (volunteer: IBasicVolunteerResponse): string =>
      volunteer.volunteerSchoolsGrades?.find((school) => +school.schoolId === schoolId)?.gradeId || '',
    [schoolId]
  );

  const monthly = useCallback(
    (volunteer: IBasicVolunteerResponse): string | number =>
      orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
        ? convertTimeFromSecondsToHours(volunteer.spendTime)
        : getVolunteerTotalSpendTimeByCurrentOrg(orgId, volunteer?.volunteerSpendTime),
    [orgGeneralType, orgId]
  );

  const history = useHistory();

  useEffect(() => {
    resetVolunteers();
    setPagination(INITIAL_PAGINATION);
    const queryParams = qs.parse(location.search, {ignoreQueryPrefix: true});
    setRequestBody({...queryParams, genderId: queryParams?.genderId ? +queryParams?.genderId : undefined});
    disableBodyScroll(document.body, {reserveScrollBarGap: true});
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const {isLoading, sort, setPagination, changeVisibilityFilters, setRequestBody}: VolunteersPageContextType =
    useContext(VolunteersPageContext);

  const setCheckedVolunteers = (checked: boolean, value?: string) =>
    checked && value
      ? setCurrentCheckedVolunteersId([...currentCheckedVolunteersId, Number(value)])
      : setCurrentCheckedVolunteersId(currentCheckedVolunteersId.filter((id) => id !== Number(value)));

  const closeTableClickHandler = () => {
    setIsCloseTable(true);
    setTimeout(() => setIsShowSelectableVolunteersTable(false), 200);
  };

  const searchVolunteersChangeHandler = (value: string): void => {
    const fullTextSearchParams: IFullTextSearchVolunteers = {
      value,
      fields: Object.values(SEARCH_VOLUNTEER_POSSIBLE_SEARCH),
    };
    if (fullTextSearchParams.value.length) {
      appendQueryString(fullTextSearchParams, 'fullTextSearch');
    } else {
      const searchParams = qs.parse(location.search, {ignoreQueryPrefix: true});
      delete searchParams.fullTextSearch;
      history.push({search: qs.stringify(searchParams)});
    }
  };

  const appendQueryString = (
    newQueryParams: ISearchVolunteersSort | IFullTextSearchVolunteers,
    searchBy: string
  ): void => {
    const searchQuery = setSearchOrSortQueryString(location.search, newQueryParams, searchBy);
    history.push({search: searchQuery});
  };

  const buttonFailureClickHandler = async () => {
    history.push({search: ''});
    setPagination(INITIAL_PAGINATION);
  };

  const headerSortClickHandler = (sortBy: PossibleSortBy) => {
    const newQueryParams = getHeaderSortQueryParams(sort?.sortDir, sortBy as SEARCH_VOLUNTEER_POSSIBLE_SORT);
    appendQueryString(newQueryParams as ISearchVolunteersSort, 'sort');
  };
  const headerSortDescFirstClickHandler = (sortBy: PossibleSortBy) => {
    const newQueryParams = getHeaderSortQueryParams(sort?.sortDir, sortBy as SEARCH_VOLUNTEER_POSSIBLE_SORT, true);
    appendQueryString(newQueryParams as ISearchVolunteersSort, 'sort');
  };

  const monthlySortBy =
    orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
      ? SEARCH_VOLUNTEER_POSSIBLE_SORT.SpendTime
      : SEARCH_VOLUNTEER_POSSIBLE_SORT.OrganisationSpendTime;

  return (
    <div className={cx(styles.container)}>
      <div className={styles.table}>
        <h2 className="title">{tableTitle}</h2>
        <TableMenu
          changeVisibilityFilters={changeVisibilityFilters}
          searchChangeHandler={searchVolunteersChangeHandler}
        >
          <Actions
            isEditSmartGroup={isEditSmartGroup}
            closeTableClickHandler={closeTableClickHandler}
            checkedVolunteersId={checkedVolunteersId}
            currentCheckedVolunteersId={currentCheckedVolunteersId}
            setCheckedVolunteersId={setCheckedVolunteersId}
          />
        </TableMenu>
        {!isLoading && error.state ? (
          <ResponseFailure
            styleTable
            message={error.message || t('errors:couldntFindVolunteers')}
            buttonClickHandler={() => {
              setPagination(INITIAL_PAGINATION);
              resetError();
            }}
            buttonTitle={t('buttons:button.getAll')}
          />
        ) : !volunteersRecords.length && !isLoading ? (
          <ResponseFailure
            styleTable
            message={errorMessage}
            buttonTitle={t('buttons:button.getAll')}
            buttonClickHandler={buttonFailureClickHandler}
          />
        ) : !volunteersRecords.length && isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : (
          <div className="containerRef">
            <TableMain>
              <StickyHeader>
                <TableHead callChild="smart_group" className={styles.table__header}>
                  <TableHeadCell />
                  <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.avatar} text={TABLE_HEADERS.avatar} />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.fullName}
                    text={TABLE_HEADERS.fullName}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.FirstLastName}}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.email}
                    text={TABLE_HEADERS.email}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Email}}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.phone}
                    text={TABLE_HEADERS.phoneNumber}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.PhoneNumber}}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.age}
                    text={TABLE_HEADERS.age}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.BirthDate}}
                  />
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.gender}
                    text={TABLE_HEADERS.gender}
                    clickSortHandler={headerSortClickHandler}
                    sortProps={{sort, ownSortBy: SEARCH_VOLUNTEER_POSSIBLE_SORT.Gender}}
                  />
                  <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.location} text={TABLE_HEADERS.location} />
                  {orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL && (
                    <TableHeadCell itemClassName={TABLE_ITEMS_CLASSNAMES.grade} text={TABLE_HEADERS.grade} />
                  )}
                  <TableHeadCellWithSort
                    itemClassName={TABLE_ITEMS_CLASSNAMES.monthly}
                    text={TABLE_HEADERS.monthly}
                    clickSortHandler={headerSortDescFirstClickHandler}
                    sortProps={{
                      sort,
                      ownSortBy: monthlySortBy,
                    }}
                  />
                </TableHead>
              </StickyHeader>
              <TableBody>
                {volunteersRecords.map((volunteer: IBasicVolunteerResponse, index: number) => (
                  <TableRow
                    key={volunteer.id}
                    callChild="smart_group"
                    className={cx(styles.table__row, tenthListItemSelector(index))}
                    suspend={volunteer.volunteerActiveStatus.status === VOLUNTEER_ACTIVE_STATUS.SUSPENDED}
                  >
                    <CheckboxVolunteerCell
                      volunteerId={+volunteer.id}
                      changeHandle={setCheckedVolunteers}
                      defaultChecked={
                        !!currentCheckedVolunteersId?.length && currentCheckedVolunteersId.includes(+volunteer.id)
                      }
                    />
                    <AvatarCell
                      imagePath={volunteer?.imagePath || ''}
                      avatarTitle={t(`popup:profilePicture.${schoolId ? 'student' : 'volunteer'}`)}
                      disablePopupZeroTop
                    />
                    <FullNameCell
                      firstName={volunteer.firstName}
                      lastName={volunteer.lastName}
                      createDate={volunteer.createDate}
                    />
                    <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.email} text={volunteer.email || ''} />
                    <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.phone} text={volunteer.phoneNumber || ''} />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.age}
                      text={calculateUserAge(volunteer.birthDate) || t('volunteers:noData')}
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.gender}
                      text={generateGenderName(volunteer.gender?.genderName)}
                    />
                    <CellDefault
                      parentClassName={TABLE_ITEMS_CLASSNAMES.location}
                      text={generateLocation(volunteer.address)}
                    />
                    {orgGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL && (
                      <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.grade} text={studentGrade(volunteer)} />
                    )}
                    <CellDefault parentClassName={TABLE_ITEMS_CLASSNAMES.monthly} text={monthly(volunteer)} />
                  </TableRow>
                ))}
              </TableBody>
            </TableMain>
          </div>
        )}
        {!!volunteersRecords.length && volunteersRecords.length < volunteersTotal && !error.state && (
          <Observer paginationSkip={volunteersRecords.length} setPagination={setPagination} isLoading={isLoading} />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  resetError,
  resetVolunteers,
};

const connector = connect(null, mapDispatchToProps);

type SelectableVolunteersTableProps = ConnectedProps<typeof connector> & SelectableVolunteersTableParentProps;

export default connector(SelectableVolunteersTable);
