import {Dispatch, SetStateAction, SyntheticEvent} from 'react';
import moment from 'moment';
import qs from 'qs';
//translate
import i18n from 'components/translate';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  GenerateDynamicLinkRequest,
  GetVolunteerByIdResponse,
  IBasicVolunteerResponse,
  IGetLocationAddressByIdResponse,
  IGetLocationIdByAddressRequest,
  IGetVolunteerByIdResponse,
  IOpportunityResponse,
  ITimeRange,
  IVolunteerSpendTimeResponse,
  OPPORTUNITY_LOCATION_TYPE,
  OPPORTUNITY_STATUSES,
  PaginationRequest,
  TimeRangeRequest,
  WEEKDAYS,
  WITH_WHO,
} from '@joc/api-gateway';
import {TableTotalAmount} from 'components/Organization/Volunteers/Table/interfaces';
import {
  IMAGE_WITH_POPUP_TYPE,
  IsShowAdditionalType,
  Nullable,
  OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES,
} from 'core/types';
//images
import UpcomingSvg from 'assets/image/volFilter/upcoming.svg';
import ApprovedSvg from 'assets/image/volFilter/approved.svg';
import RejectedSvg from 'assets/image/volFilter/rejected.svg';
import CantMakeItSvg from 'assets/image/volFilter/cantmakeit.svg';
import PendingSvg from 'assets/image/Pending.svg';
import BabesSvg from 'assets/image/selects/babes.svg';
import KidsSvg from 'assets/image/selects/kids.svg';
import TeensSvg from 'assets/image/selects/teens.svg';
import AdultsSvg from 'assets/image/selects/adults2.svg';
import SeniorsSvg from 'assets/image/selects/seniors.svg';
//constants
import {HE_IS, INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER, REGEX_URL} from 'core/constants';
import {API} from 'core/API';
import {getDefaultImage} from './photo';

export * from './language';
export * from './firebase/firebaseNotification';
export * from './photo';
export * from './general';
export * from './search';
export * from './user';
export * from './validation';
export * from './chat';

export const generateLocation = (
  address: IGetLocationAddressByIdResponse | IGetLocationIdByAddressRequest | string | undefined
): string => {
  if (typeof address === 'string') return address;

  let formattedAddress = '';

  if (address?.buildingNumber) formattedAddress += `${address.buildingNumber} `;
  if (address?.streetName) formattedAddress += `${address.streetName}, `;
  if (address?.cityName) formattedAddress += `${address.cityName}, `;
  if (address?.stateName) formattedAddress += address.stateName;
  if (address?.zipCode || address?.pobox) formattedAddress += ` ${address.zipCode || address.pobox}`;
  if (address?.countryName)
    formattedAddress += formattedAddress.length ? `, ${address.countryName}` : address.countryName;

  return formattedAddress;
};

export const excelDateToJSDate = (date: any) => new Date(Date.UTC(0, 0, date - 1));

export const formatDate = (date: Date, format: string = 'MM/DD/YY'): string => moment(date).format(format);

export const convertTimeToHoursAndMinutes = (date: Date): string => moment(date).format('h:mm A');

export const setWithWhoFilterVision = (who: string) => {
  switch (who) {
    case 'BABIES':
      return {name: i18n.t('form:filterWithWho.babies'), age: '0-3', src: BabesSvg};
    case 'KIDS':
      return {name: i18n.t('form:filterWithWho.kids'), age: '4-11', src: KidsSvg};
    case 'TEENS':
      return {name: i18n.t('form:filterWithWho.teens'), age: '12-17', src: TeensSvg};
    case 'ADULTS':
      return {name: i18n.t('form:filterWithWho.adults'), age: '18-64', src: AdultsSvg};
    case 'SENIORS':
      return {name: i18n.t('form:filterWithWho.seniors'), age: '65+', src: SeniorsSvg};
    case 'OTHER':
      return {name: i18n.t('form:filterWithWho.other'), age: '', src: ''};
    default:
      return {name: '', age: '', src: ''};
  }
};

export const addressToObj = (place: google.maps.places.PlaceResult): IGetLocationIdByAddressRequest => {
  const addressObj: IGetLocationIdByAddressRequest = {
    countryName: '',
    stateName: '',
    cityName: '',
    streetName: '',
    buildingNumber: '',
    zipCode: '',
    pobox: '',
    longitude: 0,
    latitude: 0,
  };
  if (place.address_components?.length) {
    place.address_components.forEach((el: google.maps.GeocoderAddressComponent) =>
      el.types.find((typeEl: string) => {
        switch (typeEl) {
          case 'street_number':
            addressObj.buildingNumber = el.long_name;
            break;
          case 'route':
            addressObj.streetName = el.long_name;
            break;
          case 'locality':
            addressObj.cityName = el.long_name;
            break;
          case 'administrative_area_level_1':
            addressObj.stateName = el.long_name;
            break;
          case 'country':
            addressObj.countryName = el.long_name;
            break;
          case 'postal_code':
            addressObj.zipCode = el.long_name;
            addressObj.pobox = el.long_name;
            break;
          default:
            '';
        }
      })
    );
    addressObj.longitude = place?.geometry?.location?.lng() as number;
    addressObj.latitude = place?.geometry?.location?.lat() as number;
  }
  return addressObj;
};

export const setVolunteerStatus = (status: string, oppoEndDate: Date) => {
  switch (status) {
    case OPPORTUNITY_VOLUNTEER_STATUS.PENDING:
      return {text: i18n.t('myChesed:pending'), src: PendingSvg};
    case OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT:
      return {
        text: i18n.t(`myChesed:${hasDatePassed(oppoEndDate) ? 'couldntMake' : 'cantMake'}`),
        src: CantMakeItSvg,
      };
    case OPPORTUNITY_VOLUNTEER_STATUS.REJECT:
    case OPPORTUNITY_STATUSES.CANCELED:
      return {text: i18n.t('myChesed:rejected'), src: RejectedSvg};
    case OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM:
      return {text: i18n.t('myChesed:statusReady'), src: UpcomingSvg};
    case OPPORTUNITY_STATUSES.OPEN:
      return {text: i18n.t('myChesed:approved'), src: ApprovedSvg};
    default:
      return {text: '', src: ''};
  }
};

export const sortVolunteersByName = (volunteers: Array<IGetVolunteerByIdResponse>): Array<IGetVolunteerByIdResponse> =>
  volunteers.sort((a, b) => {
    if (a.firstName && b.firstName && a.lastName && b.lastName)
      return a.firstName.localeCompare(b.firstName) !== 0
        ? a.firstName.localeCompare(b.firstName)
        : a.lastName.localeCompare(b.lastName);
    return 0;
  });

export const converTimeToMinutesAndSeconds = (startTime: number) => {
  const h = Math.floor(startTime / 3600)
    .toString()
    .padStart(2, '0');
  const m = Math.floor((startTime % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const s = Math.floor(startTime % 60)
    .toString()
    .padStart(2, '0');
  return h === '00' ? `${m}:${s}` : `${h}:${m}:${s}`;
};

export const distanceViewer = (userVolunteerId: string | undefined, oppo: IOpportunityResponse) => {
  if (oppo.isVirtual) {
    return i18n.t('oppoViewVolunteer:locationVirtual');
  }
  if (!oppo.distance) {
    return '';
  }
  if (oppo.distance > 99) {
    return `99+ ${i18n.t('form:filter.mi')}`;
  }
  if (oppo.distance < 1) {
    return `${i18n.t('common:less')} 1 ${i18n.t('form:filter.mi')}`;
  }
  return `${oppo.distance.toFixed().toString()} ${i18n.t('form:filter.mi')}`;
};

export const convertTimeFromSecondsToHours = (time?: number) => {
  if (!time) return '0h';

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);

  return `${hours}h ${minutes}m`;
};

export const getVolunteerTotalSpendTimeByCurrentOrg = (
  orgId: number | undefined,
  volunteerSpendTime?: Array<IVolunteerSpendTimeResponse>
) => {
  if (!orgId || !volunteerSpendTime) return i18n.t('volunteers:noData');

  const currentSpendTime = volunteerSpendTime?.find((i) => i.organizationId.toString() === orgId.toString())?.spendTime;
  return convertTimeFromSecondsToHours(currentSpendTime);
};

export const getAmountOppoOrganization = (
  orgId: number | undefined,
  volunteerSpendTime?: Array<IVolunteerSpendTimeResponse>
) => {
  if (!orgId || !volunteerSpendTime) return i18n.t('volunteers:noData');

  return volunteerSpendTime?.find((i) => i.organizationId.toString() === orgId.toString())?.opportunityAmount || 0;
};

export const getAmountOppoSchool = (volunteer: IBasicVolunteerResponse, option: TableTotalAmount) => {
  return option === TableTotalAmount.Year ? volunteer.yearOpportunityAmount : volunteer.totalOpportunityAmount;
};

export const convertFileSizeToMb = (size: number) => +(size / (1024 * 1024)).toFixed(2);

export const isVolunteerCantMakeIt = (
  volunteers: Array<GetVolunteerByIdResponse> | undefined,
  currentVolunteerId: string | undefined
) =>
  !!(
    currentVolunteerId &&
    volunteers?.find(
      (volunteer) =>
        volunteer.id === currentVolunteerId && volunteer.status === OPPORTUNITY_VOLUNTEER_STATUS.CANT_MAKE_IT
    )
  );

export const getUserPhotoSrc = (imagePath: string | undefined | null, type?: IMAGE_WITH_POPUP_TYPE): string => {
  if (!imagePath) return getDefaultImage(type);

  if (!!imagePath.match(REGEX_URL)?.length) return imagePath;

  return `${process.env.REACT_APP_S3_API}/${imagePath}`;
};

export const isMyOppo = (
  volunteers: Array<GetVolunteerByIdResponse> | undefined,
  currentVolunteerId: string | undefined
) => volunteers?.some((volunteer) => volunteer.id === currentVolunteerId);

export const getMyStatusInOppo = (
  volunteers: Array<GetVolunteerByIdResponse> | undefined,
  currentVolunteerId: string | undefined
) => {
  volunteers?.find((volunteer) => volunteer.id === currentVolunteerId)?.status;
};

export const convertWhatTimeToGreenwich = (whatTime: ITimeRange | undefined): ITimeRange => {
  const timeRange: ITimeRange = {};
  const dateStr = moment().format('YYYY.MM.DD');
  const startTime =
    whatTime?.startTime && moment(`${dateStr} ${whatTime.startTime}`, 'YYYY.MM.DD HH:mm').utc().format('HH:mm:ss');
  TimeRangeRequest.fromJS({
    startTime,
  });
  if (startTime) timeRange.startTime = startTime;
  const endTime =
    whatTime?.endTime && moment(`${dateStr} ${whatTime.endTime}`, 'YYYY.MM.DD HH:mm').utc().format('HH:mm:ss');
  TimeRangeRequest.fromJS({
    endTime,
  });
  if (endTime) timeRange.endTime = endTime;

  return timeRange;
};

export const deepLinkTemplate = (link: string) => {
  const appEnv = process.env.REACT_APP_JOCP_ENV;

  return appEnv === 'DEV'
    ? `https://app-dev.justonechesed.page.link/?link=${link}&apn=com.volunteer_project.dev&isi=1589218541&ibi=joc.app.com`
    : `https://app.justonechesed.page.link/?link=${link}&apn=com.volunteer_project&isi=1589218541&ibi=joc.app.com`;
};

export const getDynamicLink = async (url: string) => {
  return API.generateDynamicLink(GenerateDynamicLinkRequest.fromJS({url}));
};

export const checkTypesVolunteerOpportunitiesFilter = (
  values: OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES,
  type: OPPORTUNITY_LOCATION_TYPE
) => {
  values.opportunityLocationType?.includes(type)
    ? (values.opportunityLocationType = values.opportunityLocationType.filter((item) => item !== type))
    : values.opportunityLocationType.push(type);
};

export const parseQueryStringVolunteerOpportunitiesFilter = (
  queryString: string
): OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES => {
  const queryParams = qs.parse(queryString, {ignoreQueryPrefix: true});

  const requestBody: OPPORTUNITIES_FILTERS_VOLUNTEER_VALUES = {
    opportunityLocationType:
      (queryParams.opportunityLocationType as Array<OPPORTUNITY_LOCATION_TYPE>) ||
      INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.opportunityLocationType,
    withWho: (queryParams?.withWho as Array<WITH_WHO>) || INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.withWho,
    organisationId: (queryParams?.organisationId as string) ? +(queryParams.organisationId as string) : undefined,
    weekDays: (queryParams?.weekDays as Array<WEEKDAYS>) || INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.weekDays,
    startTime: (queryParams?.whatTime as ITimeRange)?.startTime || INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.startTime,
    endTime: (queryParams?.whatTime as ITimeRange)?.endTime || INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.endTime,
    fromDate: (queryParams?.fromDate as string)
      ? new Date(queryParams.fromDate as string)
      : INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.fromDate,
    toDate: (queryParams?.toDate as string)
      ? new Date(queryParams.toDate as string)
      : INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.toDate,
    howFar: (queryParams?.howFar as string)
      ? +(queryParams.howFar as string)
      : INITIAL_OPPORTUNITIES_FILTERS_VOLUNTEER.howFar,
  };
  return requestBody;
};

export const changeVisibilityAdditionalOpportunityMainForm = (
  isShowAdditional: IsShowAdditionalType,
  setIsShowAdditional: Dispatch<SetStateAction<IsShowAdditionalType>>
) => {
  setIsShowAdditional({isShow: isShowAdditional.isShow, isClose: !isShowAdditional.isClose});
  setTimeout(() => setIsShowAdditional({isShow: !isShowAdditional.isShow, isClose: isShowAdditional.isClose}), 200);
};

export const removeEmptyProps = (obj: any, isRemoveFalse = false) => {
  if (!obj) {
    return {};
  }

  const newObj = {...obj};
  Object.entries(newObj)
    .filter((item) => {
      const val = item[1];
      return (
        (typeof val === 'boolean' && isRemoveFalse && !val) ||
        (typeof val !== 'number' && typeof val !== 'boolean' && !val) || // if empty string/null/undefined
        (Array.isArray(val) && val.length === 0) || // if empty array
        val === 'Invalid date'
      );
    })
    .forEach((item) => delete newObj[item[0]]);
  return newObj;
};

export const getFileType = (file: string): string => file.split('.').pop() || '';

export const hasDatePassed = (date: Date) => moment() > moment(date);

export const ePreventDefault = (e: SyntheticEvent) => e.preventDefault();

export const getIsEmptyPaginationSkip = (pagination?: PaginationRequest) => !pagination?.skip;

export const getTotalHours = (startDate: Date, endDate: Date): number => {
  const hourInMilliseconds = 36e5;
  return Number((Math.abs(startDate.getTime() - endDate.getTime()) / hourInMilliseconds).toFixed(2));
};

export const getDurationDetails = (duration: number, format?: 'long' | 'medium' | 'short'): string => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  let resultHours = '';
  let resultMinutes = '';

  switch (format) {
    case 'long':
      if (hours) resultHours = `${hours} ${i18n.t(hours === 1 ? 'common:time.hour' : 'common:time.hours')} `;
      if (minutes) resultMinutes = `${minutes} ${i18n.t('common:time.minutes')}`;
      break;

    case 'medium':
      if (hours) resultHours = `${hours} ${i18n.t(hours === 1 ? 'common:time.hr' : 'common:time.hrs')} `;
      if (minutes) resultMinutes = `${minutes} ${i18n.t('common:time.min')}`;
      break;

    case 'short':
    default:
      if (hours) resultHours = `${hours}${i18n.t('common:time.h')} `;
      if (minutes) resultMinutes = `${minutes}${i18n.t('common:time.m')}`;
      break;
  }

  const result = `${resultHours}${resultMinutes}`.toLowerCase();
  return getCorrectDirection(result);
};

export const getDurationDetailsWithDate = (
  duration: number,
  endDate: Date,
  startDate?: Date,
  format: 'long' | 'medium' | 'short' = 'long'
) => {
  const isFullDay = Math.floor(duration / 3600) % 24 === 0;
  const isVisibleEndDate = Math.floor(duration / 3600) >= 24;

  const adjustedEndDate = isFullDay ? startDate : endDate;

  const transformStartDate = moment(startDate).format('D MMM');
  const transformEndDate = moment(adjustedEndDate).format('D MMM');

  const durationDetails = convertTimeFromSecondsToHours(duration);

  const resultDurationDetails = durationDetails ? `, ${durationDetails}` : '';

  const result = isVisibleEndDate
    ? `${transformStartDate} - ${transformEndDate}${resultDurationDetails}`
    : `${transformEndDate}${resultDurationDetails}`;
  return getCorrectDirection(result);
};

export const getCorrectDirection = (value: string) => {
  const isHebrew = i18n.language === HE_IS;

  if (isHebrew) return `\u202B${value}`;
  return value;
};

export const getTwoDigitNumber = (number: number) =>
  Math.floor(Math.abs(number)).toString().length === 1 ? `0${number}` : `${number}`;

export function getExistingFields<T extends Record<string, unknown> = {}>(source: T) {
  const result = Object.keys(source).reduce((result, key) => {
    const value = source[key];
    if (value) return {...result, [key]: value};
    return result;
  }, {} as Partial<T>);

  return result;
}

export function getNullInsteadOfEmptyFields<T extends Record<string, unknown> = {}>(source: T) {
  const result = Object.keys(source).reduce((result, key) => {
    const value = source[key];
    if (!value && value !== undefined) return {...result, [key]: null};
    return {...result, [key]: value};
  }, {} as Nullable<T>);

  return result;
}
