import {SET_PUBLIC_DASHBOARD_DATA} from 'redux/actions-types';
import {PublicDashboardAction, PublicDashboardReducer} from './types';

const initialState: PublicDashboardReducer = {
  organizationAvatar: '',
  organizationName: '',
  opportunitiesCount: 0,
  spendTimeByOrganization: 0,
  spendTimeByAll: 0,
  recentlyOppoName: '',
  recentlyVolunteerAvatar: '',
  recentlyVolunteerGrade: '',
  recentlyVolunteerName: '',
  title: '',
  subTitle: '',
  mediaPath: '',
};

const publicDashboardReducer = (state = initialState, action: PublicDashboardAction): PublicDashboardReducer => {
  const {type, payload} = action;

  switch (type) {
    case SET_PUBLIC_DASHBOARD_DATA:
      return {...payload};
    default:
      return state;
  }
};

export default publicDashboardReducer;
